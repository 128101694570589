export const SoftSkills = [
  {
    skill: 'Teamwork',
    svg: <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 372.483 372.483">
      <g id="XMLID_10_">
        <g>
          <path d="M372.482,263.045c0-21.796-6.527-42.842-18.877-60.863c-8.883-12.962-20.322-23.726-33.578-31.725
          c15.607-14.78,25.372-35.67,25.372-58.811c0-44.684-36.354-81.038-81.037-81.038c-26.496,0-51.316,13.104-66.392,34.615
          c-3.831-0.558-7.745-0.859-11.729-0.859s-7.897,0.302-11.728,0.859c-15.077-21.511-39.897-34.615-66.392-34.615
        c-44.684,0-81.038,36.354-81.038,81.038c0,23.141,9.765,44.031,25.373,58.811c-13.257,7.998-24.696,18.763-33.578,31.724
        C6.528,220.203,0,241.249,0,263.045v45.075h78.121v33.755h216.242V308.12h78.12v-45.075H372.482z M264.362,50.609
        c33.656,0,61.037,27.381,61.037,61.038s-27.381,61.038-61.037,61.038c-0.603,0-1.206-0.009-1.81-0.027
        c0.046-0.128,0.084-0.259,0.129-0.387c1.042-2.954,1.911-5.988,2.608-9.087c0.049-0.22,0.105-0.438,0.152-0.659
        c0.181-0.836,0.34-1.68,0.495-2.526c0.073-0.4,0.148-0.799,0.215-1.2c0.126-0.755,0.24-1.513,0.345-2.274
        c0.073-0.529,0.139-1.058,0.202-1.588c0.079-0.672,0.156-1.345,0.219-2.022c0.064-0.691,0.112-1.383,0.158-2.076
        c0.036-0.546,0.081-1.09,0.106-1.639c0.055-1.177,0.086-2.356,0.09-3.536c0-0.087,0.007-0.172,0.007-0.26
        c0-0.047-0.004-0.094-0.004-0.141c-0.002-1.205-0.037-2.403-0.091-3.597c-0.016-0.347-0.037-0.693-0.057-1.039
        c-0.059-1.015-0.136-2.025-0.231-3.03c-0.022-0.228-0.04-0.456-0.063-0.683c-3.022-29.209-21.475-54.21-48.095-65.774
        C230.208,58.253,246.8,50.609,264.362,50.609z M165.122,88.131c0.195-0.071,0.392-0.138,0.587-0.208
        c0.607-0.217,1.219-0.423,1.834-0.622c0.466-0.149,0.935-0.293,1.407-0.431c0.343-0.101,0.687-0.198,1.032-0.294
        c0.616-0.169,1.235-0.334,1.86-0.485c4.62-1.122,9.438-1.731,14.397-1.731c4.963,0.004,9.783,0.614,14.404,1.736
        c0.621,0.15,1.236,0.314,1.849,0.482c0.35,0.097,0.7,0.195,1.047,0.298c0.466,0.137,0.93,0.279,1.391,0.426
        c0.623,0.201,1.242,0.409,1.856,0.629c0.189,0.067,0.378,0.131,0.566,0.2c20.413,7.549,35.718,25.716,39.182,47.763
        c0.027,0.176,0.053,0.353,0.079,0.53c0.111,0.747,0.204,1.5,0.287,2.255c0.03,0.278,0.062,0.555,0.089,0.834
        c0.066,0.689,0.115,1.383,0.159,2.079c0.018,0.298,0.043,0.595,0.057,0.894c0.042,0.889,0.065,1.783,0.068,2.682
        c0,0.078,0.005,0.154,0.005,0.232c0,0.036-0.002,0.073-0.002,0.109c-0.002,0.87-0.028,1.734-0.066,2.595
        c-0.012,0.271-0.024,0.542-0.04,0.812c-0.047,0.832-0.109,1.66-0.189,2.482c-0.031,0.318-0.073,0.635-0.109,0.952
        c-0.061,0.532-0.129,1.063-0.203,1.591c-0.058,0.411-0.116,0.823-0.182,1.232c-0.101,0.623-0.213,1.242-0.333,1.859
        c-0.107,0.549-0.224,1.096-0.346,1.642c-0.073,0.329-0.149,0.658-0.228,0.985c-0.195,0.811-0.409,1.617-0.637,2.42
        c-0.091,0.319-0.176,0.64-0.272,0.956c-0.139,0.46-0.289,0.916-0.438,1.372c-0.12,0.365-0.243,0.728-0.37,1.09
        c-0.144,0.411-0.286,0.823-0.438,1.231c-0.259,0.693-0.531,1.38-0.814,2.061c-0.126,0.304-0.26,0.605-0.391,0.908
        c-0.247,0.567-0.501,1.131-0.765,1.689c-0.031,0.066-0.062,0.132-0.093,0.198c-0.095,0.198-0.189,0.397-0.286,0.595
        c-0.296,0.604-0.604,1.201-0.92,1.793c-0.123,0.23-0.242,0.463-0.368,0.692c-0.008,0.015-0.016,0.029-0.024,0.043
        c-0.346,0.63-0.707,1.25-1.074,1.866c-0.146,0.244-0.29,0.489-0.439,0.73c-0.4,0.649-0.811,1.291-1.234,1.923
        c-0.263,0.393-0.538,0.778-0.81,1.165c-0.231,0.328-0.461,0.657-0.698,0.98c-0.307,0.418-0.62,0.831-0.938,1.242
        c-0.216,0.279-0.434,0.556-0.654,0.832c-0.336,0.42-0.675,0.838-1.021,1.249c-0.215,0.255-0.436,0.505-0.655,0.756
        c-0.36,0.413-0.719,0.827-1.089,1.23c-0.22,0.239-0.448,0.469-0.672,0.705c-0.602,0.635-1.218,1.256-1.846,1.865
        c-0.373,0.361-0.744,0.723-1.126,1.074c-0.264,0.243-0.535,0.479-0.804,0.718c-0.438,0.389-0.879,0.774-1.328,1.15
        c-0.228,0.191-0.458,0.38-0.689,0.568c-0.543,0.442-1.093,0.875-1.652,1.299c-0.152,0.115-0.302,0.232-0.455,0.346
        c-10.16,7.553-22.736,12.03-36.34,12.03c-13.605,0-26.183-4.478-36.343-12.033c-0.15-0.112-0.297-0.227-0.446-0.34
        c-0.561-0.425-1.114-0.861-1.659-1.305c-0.229-0.187-0.458-0.374-0.684-0.564c-0.451-0.378-0.893-0.765-1.333-1.155
        c-0.267-0.238-0.537-0.472-0.8-0.714c-0.383-0.352-0.755-0.715-1.129-1.076c-0.631-0.611-1.248-1.234-1.852-1.871
        c-0.221-0.233-0.447-0.46-0.664-0.696c-0.371-0.404-0.73-0.819-1.09-1.232c-0.219-0.251-0.439-0.5-0.654-0.755
        c-0.347-0.411-0.685-0.829-1.021-1.248c-0.22-0.276-0.439-0.553-0.655-0.833c-0.317-0.41-0.63-0.823-0.937-1.241
        c-0.237-0.324-0.468-0.654-0.699-0.983c-0.272-0.386-0.546-0.77-0.808-1.162c-0.425-0.635-0.838-1.279-1.239-1.93
        c-0.147-0.239-0.289-0.48-0.433-0.721c-0.373-0.625-0.738-1.253-1.089-1.892c-0.129-0.235-0.251-0.474-0.378-0.71
        c-0.316-0.594-0.625-1.193-0.922-1.798c-0.106-0.215-0.209-0.432-0.312-0.648c-0.016-0.033-0.031-0.066-0.046-0.099
        c-0.277-0.584-0.542-1.174-0.801-1.769c-0.123-0.283-0.248-0.565-0.366-0.849c-0.29-0.696-0.567-1.398-0.832-2.106
        c-0.143-0.382-0.275-0.768-0.41-1.153c-0.139-0.395-0.273-0.793-0.404-1.192c-0.141-0.432-0.283-0.864-0.415-1.298
        c-0.197-0.649-0.383-1.302-0.558-1.959c-0.123-0.461-0.234-0.926-0.347-1.39c-0.088-0.365-0.173-0.73-0.254-1.097
        c-0.1-0.45-0.201-0.899-0.291-1.351c-0.139-0.704-0.266-1.412-0.38-2.124c-0.062-0.384-0.116-0.769-0.171-1.153
        c-0.081-0.569-0.154-1.141-0.219-1.715c-0.032-0.283-0.069-0.566-0.098-0.849c-0.083-0.843-0.146-1.692-0.194-2.544
        c-0.014-0.245-0.025-0.49-0.036-0.735c-0.041-0.911-0.07-1.825-0.07-2.745c0-0.986,0.028-1.965,0.075-2.94
        c0.013-0.265,0.035-0.528,0.05-0.792c0.044-0.732,0.097-1.462,0.167-2.188c0.024-0.253,0.053-0.505,0.08-0.757
        c0.086-0.786,0.183-1.568,0.299-2.345c0.022-0.151,0.044-0.303,0.068-0.454C129.393,113.864,144.701,95.685,165.122,88.131z
        M47.083,111.647c0-33.656,27.381-61.038,61.038-61.038c17.563,0,34.154,7.645,45.625,20.529
        c-26.629,11.568-45.086,36.581-48.099,65.802c-0.021,0.198-0.036,0.397-0.055,0.596c-0.1,1.041-0.179,2.086-0.239,3.137
        c-0.018,0.32-0.038,0.639-0.053,0.96c-0.057,1.251-0.096,2.506-0.096,3.77c0,1.276,0.037,2.549,0.097,3.821
        c0.024,0.512,0.066,1.019,0.099,1.528c0.049,0.732,0.099,1.464,0.167,2.194c0.06,0.649,0.134,1.294,0.21,1.939
        c0.066,0.562,0.136,1.122,0.214,1.682c0.102,0.735,0.212,1.467,0.333,2.196c0.073,0.433,0.153,0.865,0.232,1.297
        c0.15,0.816,0.303,1.63,0.477,2.437c0.058,0.266,0.124,0.53,0.184,0.795c0.679,3.003,1.522,5.943,2.526,8.81
        c0.064,0.185,0.12,0.372,0.185,0.556c-0.603,0.018-1.207,0.027-1.809,0.027C74.464,172.684,47.083,145.303,47.083,111.647z
        M94.888,239.148c-0.22,0.347-0.43,0.7-0.646,1.049c-0.457,0.738-0.91,1.478-1.348,2.225c-0.245,0.417-0.481,0.837-0.719,1.257
        c-0.394,0.693-0.783,1.388-1.161,2.089c-0.238,0.441-0.472,0.884-0.704,1.327c-0.364,0.697-0.719,1.398-1.067,2.101
        c-0.219,0.442-0.437,0.883-0.649,1.327c-0.354,0.741-0.695,1.488-1.032,2.236c-0.183,0.407-0.371,0.811-0.548,1.22
        c-0.39,0.896-0.762,1.799-1.127,2.705c-0.107,0.265-0.221,0.527-0.325,0.792c-0.463,1.177-0.906,2.362-1.326,3.553
        c-0.096,0.273-0.182,0.549-0.277,0.822c-0.316,0.918-0.624,1.839-0.915,2.764c-0.133,0.425-0.256,0.852-0.384,1.278
        c-0.235,0.784-0.466,1.569-0.683,2.359c-0.129,0.47-0.252,0.941-0.375,1.413c-0.198,0.758-0.388,1.518-0.568,2.281
        c-0.115,0.483-0.227,0.966-0.334,1.45c-0.172,0.773-0.333,1.549-0.488,2.326c-0.093,0.467-0.189,0.934-0.276,1.403
        c-0.157,0.846-0.298,1.696-0.435,2.547c-0.064,0.4-0.135,0.798-0.195,1.198c-0.186,1.25-0.355,2.503-0.497,3.762
        c-0.002,0.015-0.003,0.03-0.004,0.045c-0.129,1.145-0.24,2.292-0.333,3.443H20v-25.075c0-34.258,19.694-65.013,50.548-79.631
        c11.24,5.908,24.017,9.271,37.572,9.271c3.92,0,7.852-0.29,11.754-0.864c0.608,0.867,1.226,1.725,1.866,2.567
        c0.216,0.283,0.44,0.559,0.66,0.839c0.632,0.808,1.273,1.607,1.935,2.39c0.271,0.321,0.549,0.635,0.825,0.952
        c0.62,0.712,1.25,1.415,1.894,2.104c0.338,0.363,0.682,0.72,1.026,1.077c0.589,0.607,1.186,1.205,1.793,1.794
        c0.228,0.221,0.443,0.455,0.673,0.674c-0.869,0.525-1.73,1.063-2.583,1.612c-0.139,0.089-0.275,0.183-0.413,0.273
        c-0.853,0.554-1.697,1.12-2.533,1.697c-0.289,0.2-0.577,0.402-0.865,0.604c-0.745,0.525-1.482,1.059-2.213,1.602
        c-0.231,0.172-0.465,0.34-0.694,0.514c-0.93,0.703-1.849,1.419-2.756,2.151c-0.198,0.16-0.392,0.325-0.59,0.487
        c-0.722,0.591-1.437,1.192-2.143,1.802c-0.274,0.236-0.546,0.474-0.818,0.713c-0.707,0.623-1.405,1.255-2.096,1.896
        c-0.18,0.167-0.364,0.331-0.543,0.5c-0.848,0.798-1.683,1.611-2.506,2.438c-0.214,0.215-0.423,0.434-0.635,0.65
        c-0.63,0.643-1.253,1.293-1.868,1.952c-0.244,0.262-0.488,0.524-0.73,0.789c-0.692,0.756-1.374,1.522-2.045,2.299
        c-0.105,0.122-0.214,0.24-0.319,0.363c-0.765,0.893-1.513,1.803-2.251,2.722c-0.206,0.256-0.407,0.516-0.61,0.775
        c-0.555,0.705-1.102,1.417-1.64,2.136c-0.208,0.278-0.417,0.554-0.622,0.834c-0.704,0.96-1.399,1.928-2.074,2.913
        C96.274,236.998,95.572,238.069,94.888,239.148z M98.121,321.876v-13.755v-11.32c0-1.11,0.021-2.216,0.062-3.319
        c0.013-0.363,0.042-0.723,0.06-1.085c0.036-0.736,0.07-1.473,0.124-2.206c0.033-0.445,0.083-0.886,0.123-1.33
        c0.058-0.646,0.11-1.293,0.182-1.935c0.053-0.47,0.122-0.937,0.182-1.406c0.078-0.611,0.151-1.223,0.241-1.831
        c0.073-0.484,0.161-0.964,0.241-1.446c0.098-0.586,0.189-1.174,0.299-1.757c0.093-0.493,0.201-0.981,0.302-1.472
        c0.116-0.566,0.227-1.134,0.354-1.696c0.112-0.496,0.24-0.987,0.36-1.481c0.135-0.55,0.264-1.103,0.409-1.65
        c0.132-0.497,0.278-0.989,0.418-1.482c0.152-0.537,0.3-1.075,0.462-1.608c0.151-0.496,0.317-0.988,0.476-1.481
        c0.17-0.523,0.335-1.047,0.514-1.566c0.17-0.491,0.353-0.978,0.531-1.465c0.188-0.514,0.372-1.03,0.569-1.54
        c0.188-0.485,0.388-0.964,0.583-1.446c0.205-0.503,0.407-1.009,0.621-1.508c0.207-0.481,0.425-0.957,0.64-1.433
        c0.221-0.491,0.44-0.984,0.67-1.47c0.225-0.475,0.461-0.944,0.694-1.415c0.238-0.48,0.473-0.961,0.719-1.436
        c0.243-0.468,0.496-0.93,0.747-1.393c0.254-0.469,0.505-0.938,0.767-1.402c0.26-0.46,0.53-0.913,0.797-1.368
        c0.27-0.458,0.538-0.917,0.817-1.371c0.277-0.451,0.563-0.895,0.848-1.341c0.286-0.447,0.57-0.894,0.864-1.336
        s0.596-0.878,0.898-1.314c0.301-0.435,0.601-0.87,0.909-1.3c0.31-0.432,0.629-0.858,0.948-1.285
        c0.316-0.423,0.631-0.846,0.954-1.263c0.326-0.421,0.66-0.836,0.994-1.252c0.331-0.412,0.663-0.823,1.001-1.229
        c0.341-0.408,0.689-0.811,1.037-1.213c0.347-0.401,0.695-0.802,1.05-1.198c0.355-0.396,0.718-0.786,1.08-1.176
        c0.362-0.389,0.724-0.776,1.093-1.158c0.37-0.384,0.747-0.762,1.125-1.139c0.377-0.377,0.756-0.752,1.14-1.123
        c0.383-0.369,0.772-0.733,1.163-1.096c0.392-0.364,0.786-0.727,1.185-1.085c0.398-0.356,0.8-0.707,1.205-1.056
        c0.406-0.351,0.815-0.7,1.228-1.044c0.411-0.342,0.826-0.679,1.244-1.014c0.421-0.338,0.845-0.673,1.273-1.004
        c0.423-0.326,0.85-0.648,1.279-0.968c0.436-0.325,0.875-0.646,1.319-0.963c0.434-0.311,0.872-0.617,1.313-0.921
        c0.452-0.311,0.906-0.618,1.364-0.921c0.445-0.294,0.894-0.585,1.345-0.872c0.467-0.297,0.937-0.59,1.41-0.878
        c0.456-0.278,0.915-0.553,1.377-0.823c0.48-0.281,0.964-0.558,1.451-0.831c0.467-0.262,0.937-0.521,1.41-0.775
        c0.495-0.266,0.994-0.527,1.496-0.785c0.476-0.245,0.954-0.486,1.436-0.723c0.379-0.186,0.762-0.366,1.145-0.547
        c11.239,5.908,24.018,9.27,37.572,9.27c13.555,0,26.333-3.362,37.572-9.27c0.38,0.18,0.76,0.359,1.136,0.543
        c0.488,0.24,0.971,0.484,1.453,0.731c0.496,0.255,0.99,0.513,1.48,0.776c0.478,0.257,0.953,0.519,1.425,0.784
        c0.481,0.27,0.959,0.543,1.433,0.821c0.469,0.274,0.934,0.553,1.396,0.835c0.467,0.285,0.93,0.573,1.39,0.866
        c0.458,0.291,0.912,0.585,1.363,0.884c0.453,0.299,0.902,0.603,1.348,0.91c0.445,0.307,0.888,0.616,1.327,0.93
        c0.439,0.314,0.873,0.632,1.305,0.954c0.434,0.322,0.865,0.647,1.292,0.977c0.424,0.328,0.844,0.66,1.262,0.995
        c0.42,0.337,0.838,0.676,1.252,1.02c0.411,0.342,0.817,0.689,1.221,1.038c0.407,0.351,0.812,0.704,1.212,1.063
        c0.396,0.355,0.787,0.715,1.176,1.076c0.394,0.366,0.787,0.734,1.174,1.107c0.381,0.367,0.756,0.739,1.129,1.112
        c0.38,0.38,0.76,0.761,1.133,1.147c0.366,0.38,0.727,0.764,1.086,1.15c0.366,0.393,0.731,0.787,1.089,1.186
        c0.352,0.392,0.697,0.789,1.041,1.187c0.351,0.405,0.702,0.811,1.045,1.223c0.337,0.403,0.666,0.812,0.995,1.221
        c0.336,0.418,0.672,0.835,1,1.259c0.322,0.415,0.635,0.836,0.949,1.257c0.32,0.428,0.64,0.857,0.952,1.291
        c0.307,0.427,0.606,0.861,0.905,1.294c0.303,0.438,0.607,0.876,0.902,1.32c0.292,0.439,0.574,0.883,0.858,1.327
        c0.287,0.449,0.575,0.897,0.854,1.351c0.276,0.449,0.542,0.905,0.81,1.359c0.27,0.458,0.542,0.915,0.803,1.378
        c0.261,0.462,0.511,0.93,0.764,1.396c0.252,0.465,0.506,0.929,0.749,1.398c0.246,0.474,0.48,0.955,0.718,1.434
        c0.233,0.471,0.47,0.941,0.695,1.417c0.23,0.486,0.448,0.977,0.668,1.467c0.216,0.479,0.435,0.955,0.642,1.438
        c0.213,0.495,0.412,0.996,0.616,1.496c0.198,0.486,0.4,0.97,0.589,1.46c0.196,0.507,0.379,1.02,0.566,1.532
        c0.179,0.489,0.362,0.976,0.532,1.469c0.18,0.521,0.346,1.048,0.516,1.573c0.159,0.491,0.324,0.98,0.474,1.475
        c0.163,0.533,0.31,1.072,0.463,1.609c0.14,0.493,0.286,0.984,0.418,1.481c0.145,0.547,0.274,1.099,0.409,1.65
        c0.121,0.493,0.248,0.985,0.36,1.481c0.127,0.562,0.237,1.129,0.354,1.694c0.101,0.491,0.209,0.98,0.302,1.474
        c0.109,0.578,0.199,1.161,0.297,1.743c0.081,0.487,0.171,0.971,0.244,1.461c0.09,0.603,0.162,1.211,0.24,1.817
        c0.061,0.473,0.131,0.943,0.184,1.418c0.072,0.642,0.124,1.289,0.182,1.935c0.04,0.444,0.09,0.885,0.123,1.33
        c0.055,0.733,0.088,1.471,0.124,2.208c0.018,0.361,0.047,0.721,0.06,1.083c0.041,1.103,0.062,2.209,0.062,3.319v11.319v13.757
        H98.121z M294.015,288.12c-0.196-2.436-0.478-4.857-0.836-7.263c-0.057-0.381-0.125-0.759-0.186-1.139
        c-0.139-0.871-0.284-1.74-0.445-2.606c-0.085-0.456-0.178-0.91-0.269-1.365c-0.157-0.791-0.321-1.58-0.496-2.366
        c-0.106-0.474-0.216-0.948-0.328-1.42c-0.183-0.772-0.375-1.541-0.575-2.308c-0.121-0.465-0.242-0.929-0.37-1.393
        c-0.219-0.798-0.453-1.592-0.691-2.385c-0.126-0.418-0.246-0.837-0.377-1.254c-0.295-0.939-0.607-1.873-0.928-2.805
        c-0.09-0.26-0.172-0.523-0.264-0.783c-0.422-1.195-0.865-2.383-1.33-3.564c-0.1-0.253-0.209-0.503-0.311-0.756
        c-0.369-0.917-0.747-1.832-1.142-2.74c-0.175-0.403-0.36-0.801-0.54-1.202c-0.34-0.756-0.684-1.509-1.042-2.257
        c-0.209-0.438-0.425-0.873-0.64-1.308c-0.351-0.71-0.71-1.417-1.077-2.12c-0.23-0.44-0.461-0.878-0.697-1.315
        c-0.38-0.703-0.77-1.401-1.166-2.097c-0.238-0.418-0.473-0.836-0.716-1.251c-0.44-0.751-0.895-1.494-1.355-2.236
        c-0.214-0.346-0.422-0.695-0.64-1.038c-0.685-1.079-1.387-2.15-2.113-3.209c-0.676-0.986-1.371-1.954-2.075-2.914
        c-0.204-0.278-0.412-0.553-0.618-0.829c-0.541-0.723-1.09-1.437-1.647-2.145c-0.201-0.255-0.4-0.513-0.604-0.766
        c-0.739-0.922-1.49-1.834-2.257-2.729c-0.1-0.116-0.203-0.229-0.303-0.345c-0.676-0.783-1.364-1.555-2.061-2.317
        c-0.24-0.262-0.481-0.521-0.723-0.781c-0.618-0.663-1.245-1.318-1.879-1.964c-0.209-0.213-0.414-0.428-0.625-0.639
        c-0.825-0.828-1.663-1.644-2.513-2.444c-0.175-0.165-0.355-0.325-0.531-0.489c-0.695-0.645-1.397-1.28-2.107-1.906
        c-0.27-0.238-0.542-0.474-0.814-0.71c-0.708-0.611-1.423-1.212-2.146-1.804c-0.197-0.161-0.39-0.326-0.588-0.486
        c-0.906-0.732-1.825-1.448-2.754-2.15c-0.232-0.175-0.468-0.345-0.701-0.518c-0.728-0.54-1.461-1.072-2.203-1.594
        c-0.291-0.205-0.582-0.409-0.874-0.611c-0.828-0.571-1.663-1.131-2.507-1.679c-0.148-0.096-0.293-0.196-0.441-0.291
        c-0.852-0.547-1.71-1.084-2.578-1.608c0.224-0.213,0.434-0.44,0.655-0.656c0.62-0.6,1.229-1.21,1.829-1.83
        c0.335-0.346,0.668-0.693,0.996-1.045c0.655-0.7,1.293-1.414,1.923-2.137c0.268-0.307,0.537-0.612,0.8-0.924
        c0.671-0.794,1.323-1.605,1.964-2.426c0.21-0.268,0.425-0.532,0.631-0.803c0.643-0.845,1.264-1.707,1.875-2.577
        c3.902,0.573,7.834,0.863,11.754,0.863c13.555,0,26.333-3.362,37.572-9.271c30.855,14.618,50.548,45.373,50.548,79.631v25.074
        H294.015z" fill="#FFC139" />
        </g>
      </g>

    </svg>
  },
  {
    skill: 'Communication',
    svg: <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 297" enable-background="new 0 0 297 297">
      <g>
        <path d="m220.037,71.565c19.73,0 35.783-16.052 35.783-35.783s-16.053-35.782-35.783-35.782-35.783,16.052-35.783,35.783 16.052,35.782 35.783,35.782zm0-54.594c10.372-3.55271e-15 18.811,8.439 18.811,18.811s-8.439,18.811-18.811,18.811c-10.372,0-18.811-8.439-18.811-18.811s8.439-18.811 18.811-18.811z" fill="#FFC139" />
        <path d="m258.882,80.732c-0.064-0.021-9.825-3.017-9.825-3.017-4.318-1.327-8.916,0.964-10.462,5.206l-10.615,29.125c0,0-3.789-16.581-3.961-16.914l6.595-11.424c0.802-1.39 0.802-3.102 0-4.492-0.803-1.39-2.286-2.246-3.891-2.246h-13.372c-1.605,0-3.088,0.856-3.891,2.246-0.802,1.39-0.802,3.102 0,4.492l6.595,11.424c-0.171,0.333-3.961,16.914-3.961,16.914l-10.615-29.125c-1.546-4.242-6.146-6.535-10.462-5.206 0,0-9.761,2.996-9.825,3.017-14.495,4.831-24.235,18.343-24.235,33.623v58.853c0,1.656 0.485,3.276 1.394,4.66l20.255,30.823v79.823c0,4.686 3.799,8.486 8.486,8.486h65.889c4.686,0 8.486-3.799 8.486-8.486v-79.823l20.255-30.823c0.91-1.384 1.394-3.004 1.394-4.66v-58.853c0-15.28-9.739-28.792-24.234-33.623zm7.263,89.938l-20.255,30.823c-0.91,1.384-1.394,3.004-1.394,4.66v73.876h-48.918v-73.876c0-1.656-0.485-3.276-1.394-4.66l-20.255-30.823v-56.314c0-7.933 5.035-14.951 12.541-17.493l1.822-.56 23.771,65.222c1.222,3.35 4.407,5.579 7.973,5.579 3.566,0 6.751-2.23 7.973-5.579l23.771-65.222 1.822,.56c7.506,2.542 12.541,9.56 12.541,17.493v56.314z" fill="#FFC139" />
        <path d="m161.987,43.707c-1.313-3.17-4.408-5.238-7.839-5.238h-18.018c-1.49-14.78-14.003-26.355-29.169-26.355h-63.76c-16.165,0-29.317,13.152-29.317,29.317v45.058c0,16.165 13.152,29.317 29.317,29.317h63.76c16.165,0 29.317-13.152 29.317-29.317v-9.663l23.871-23.87c2.426-2.429 3.152-6.078 1.838-9.249zm-40.196,23.603c-1.591,1.592-2.485,3.751-2.485,6.001v13.178c0,6.807-5.539,12.345-12.345,12.345h-63.76c-6.807,0-12.345-5.539-12.345-12.345v-45.059c0-6.807 5.539-12.345 12.345-12.345h63.76c6.807,0 12.345,5.539 12.345,12.345v5.524c0,4.686 3.799,8.486 8.486,8.486h5.87l-11.871,11.87z" fill="#FFC139" />
      </g>
    </svg>

  },
  {
    skill: 'Adaptability',
    svg: <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 32 32">
      <g>
        <path d="M3,8h16.1c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3H29c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2.1c-0.4-1.7-2-3-3.9-3s-3.4,1.3-3.9,3H3
      C2.4,6,2,6.4,2,7S2.4,8,3,8z" fill="#FFC139" />
        <path d="M29,15H15.9c-0.4-1.7-2-3-3.9-3s-3.4,1.3-3.9,3H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h5.1c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3H29
      c0.6,0,1-0.4,1-1S29.6,15,29,15z" fill="#FFC139" />
        <path d="M29,24h-2.1c-0.4-1.7-2-3-3.9-3s-3.4,1.3-3.9,3H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h16.1c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3H29
      c0.6,0,1-0.4,1-1S29.6,24,29,24z" fill="#FFC139" />
      </g>
    </svg>
  },
  {
    skill: 'Problem Solving',
    svg: <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 466.96 466.96">
      <g>
        <path d="M310.597,170.701l44.245,15.889c1.005,0.361,2.032,0.532,3.042,0.532c3.69,0,7.152-2.288,8.471-5.96l10.694-29.78
      c0.807-2.247,0.688-4.722-0.33-6.88c-1.018-2.159-2.852-3.825-5.098-4.632l-44.245-15.889c-4.68-1.683-9.833,0.751-11.512,5.428
      l-10.694,29.78c-0.807,2.247-0.688,4.722,0.33,6.88C306.517,168.228,308.351,169.894,310.597,170.701z M329.762,143.963
      l27.304,9.805l-4.611,12.839l-27.304-9.805L329.762,143.963z" fill="#FFC139" />
        <circle cx="110.073" cy="397.876" r="9.436" fill="#FFC139" />
        <path d="M307.478,248.329l2.505-2.505c11.811-11.811,25.606-21.083,41.003-27.559l58.987-24.811c0,0,16.019-6.908,19.598-8.936
      c4.795-2.717,9.546-6.458,14.526-11.439c29.145-29.145,30.608-76.139,3.332-106.987c-2.095-2.37-5.282-3.468-8.392-2.886
      c-3.11,0.58-5.687,2.75-6.788,5.716l-21.495,57.906l-16.041-5.938l22.604-61.516c1.091-2.97,0.543-6.294-1.444-8.756
      c-1.987-2.462-5.121-3.701-8.254-3.26c-30.424,4.268-55.149,25.782-63.882,54.662l-37.125-13.743
      c-2.491-0.922-5.262-0.696-7.571,0.616c-2.309,1.313-3.921,3.578-4.403,6.189l-8.543,46.231
      c-3.997,21.634-13.356,41.589-27.243,58.383l-79.013-79.005l5.618-5.618c3.515-3.515,3.515-9.213,0-12.728l-5.937-5.937
      c18.098-22.217,41.25-23.313,58.085-19.976c3.711,0.737,7.49-0.93,9.449-4.17c1.959-3.239,1.683-7.357-0.692-10.305
      c-7.947-9.863-17.833-17.551-29.379-22.847c-27.911-12.823-60.836-8.599-84.6,10.161l-4.914-4.914
      c-3.515-3.515-9.213-3.515-12.728,0l-54.649,54.65c-3.49,3.49-3.519,9.139-0.063,12.664c3.116,3.179,1.64,10.176-3.158,14.974
      c-4.798,4.798-11.795,6.274-14.974,3.158c-3.525-3.456-9.175-3.427-12.664,0.063L2.636,156.462c-3.515,3.515-3.515,9.213,0,12.728
      l57.988,57.988c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l26.597-26.597c3.479-3.479,3.52-9.107,0.091-12.636
      c-3.093-3.183-1.608-10.171,3.177-14.956c4.784-4.784,11.772-6.27,14.955-3.177c3.528,3.43,9.157,3.389,12.636-0.09l4.969-4.969
      l79.433,79.433L84.705,374.689c-6.438,6.438-9.984,14.998-9.984,24.104c0,9.105,3.546,17.665,9.984,24.103
      c6.645,6.646,15.374,9.968,24.104,9.968c8.729,0,17.458-3.323,24.103-9.968l130.503-130.503L403.61,432.588
      c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l31.34-31.34c1.688-1.688,2.636-3.977,2.636-6.364
      c0-2.387-0.948-4.676-2.636-6.364L307.478,248.329z M394.373,69.628l-16.54,45.013l-17.156-6.351
      C365.615,90.834,378.082,76.74,394.373,69.628z M209.474,55.468c1.446,0.663,2.857,1.374,4.235,2.132
      c-18.285,2.306-34.32,11.162-46.96,26.036l-21.525-21.525C163.59,48.584,188.366,45.771,209.474,55.468z M121.106,63.448
      l45.26,45.26l-40.958,40.958l-45.26-45.26L121.106,63.448z M66.988,208.086l-45.26-45.26l13.869-13.869l45.26,45.26L66.988,208.086
      z M90.488,160.261c-3.251,3.251-5.714,6.912-7.407,10.725l-24.205-24.204c3.81-1.694,7.469-4.158,10.721-7.41
      c3.636-3.636,6.303-7.776,7.989-12.072l24.978,24.978C98.265,153.962,94.123,156.627,90.488,160.261z M148.503,152.025
      l18.608-18.608l79.437,79.429l-18.612,18.612L148.503,152.025z M120.184,410.168c-3.039,3.039-7.079,4.712-11.376,4.712
      c-4.297,0-8.337-1.673-11.375-4.712c-6.272-6.273-6.272-16.479,0-22.751L263.36,221.49c21.046-21.045,35.028-47.64,40.436-76.907
      l6.6-35.713l102.542,37.961c4.657,1.725,9.833-0.652,11.562-5.309l18.515-49.879c10.851,22.482,6.816,50.247-11.646,68.709
      c-3.817,3.817-7.309,6.599-10.674,8.506c-2.09,1.185-18.849,8.487-18.849,8.487l-57.84,24.328
      c-17.556,7.384-33.285,17.956-46.752,31.423L120.184,410.168z M409.974,413.496L276.143,279.665l18.607-18.607l133.836,133.827
      L409.974,413.496z" fill="#FFC139" />
      </g>
    </svg>

  },
  {
    skill: 'Open Mindedness',
    svg: <svg viewBox="-8.71 0 56.736 56.736" xmlns="http://www.w3.org/2000/svg">
      <path id="bulb" d="M587.65,241.553a2.959,2.959,0,0,0,2.954,2.956h15.738a2.959,2.959,0,0,0,2.953-2.956v-9.636H587.65Zm2.544-7.093h16.558v2.408H590.194Zm0,4.952h16.558v2.141a.421.421,0,0,1-.41.41H590.6a.421.421,0,0,1-.41-.41Zm8.279-51.639a19.64,19.64,0,0,0-15.911,31.18l1.073,1.406s1,1.047,1.994,2.315a8.756,8.756,0,0,1,1.807,3.082v4.368h22.052v-4.368a8.852,8.852,0,0,1,1.806-3.082c.993-1.268,2-2.311,2-2.315L614.35,219a19.643,19.643,0,0,0-15.877-31.23Zm12.92,30.862-.451.517c-.411.457-1.026,1.159-1.647,1.954-1.067,1.449-2.25,2.884-2.35,4.65,0,.724,0,1.339,0,1.825h-7.2V215.19a5.446,5.446,0,1,0-2.543,0v12.391H589.98v-1.825c-.1-1.766-1.284-3.2-2.349-4.65-.329-.42-.656-.814-.952-1.163l.007,0-1.136-1.31a17.115,17.115,0,1,1,25.843,0Zm-12.919-5.824a2.9,2.9,0,1,1,2.9-2.9A2.9,2.9,0,0,1,598.474,212.811Z" transform="translate(-578.814 -187.773)" fill="#FFC139" />
    </svg>
  },
  {
    skill: 'Time Management',
    svg: <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 300.988 300.988">
      <g>
        <g>
          <path d="M150.494,0.001C67.511,0.001,0,67.512,0,150.495s67.511,150.493,150.494,150.493s150.494-67.511,150.494-150.493
        S233.476,0.001,150.494,0.001z M150.494,285.987C75.782,285.987,15,225.206,15,150.495S75.782,15.001,150.494,15.001
        s135.494,60.782,135.494,135.493S225.205,285.987,150.494,285.987z" fill="#FFC139" />
          <polygon points="142.994,142.995 83.148,142.995 83.148,157.995 157.994,157.995 157.994,43.883 142.994,43.883 		" fill="#FFC139" />
        </g>
      </g>
    </svg>

  }
]
